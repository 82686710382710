import React from 'react';
import logo from "../assets/images/Logo.png"
import {Link} from "react-router-dom"

const Navigation=()=> {
  return (
    <header className="header-area fixed-top" id="sticky-header">
		<div className="container">
			<div className="row align-items-center">
				<div className="col-lg-2 col-md-12">
					<div className="logo">
						<Link to="/"><img src={logo} className="logo" alt="logo"/></Link>
					</div>
				</div>
				<div className="col-lg-10 col-md-12">
					<div className="header-menu">
						<ul>
							<li><Link to="/">Hjem</Link></li>
							<li><Link to="/about">Om oss</Link></li>
							<li><Link to="/contact">Kontakt</Link></li>
						</ul>
						<div className="header-right">
							<div className="Lifesafe-btn">
								<Link to="/hotels">Hotell </Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
  );
}

export default Navigation;