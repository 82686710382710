import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Contact from './pages/Contact';
import Cookie from './pages/Cookie';
import Terms from './pages/Terms';
import "./assets/css/additional.css"
import "./assets/css/bootstrapstyle.min.css"
import "./assets/css/style.min1.css"
import HotelPage from './pages/HotelPage';
import Privacy from './pages/Privacy';
import ContactInfo from './pages/ContactInfo';

const App=()=> {
  return (
    <Router>
      <div className="App">
        <Navigation />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/hotels" element={<HotelPage />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} /> 
          <Route path="/cookie" element={<Cookie />} /> 
          <Route path="/contactinfo" element={<ContactInfo />} /> 

        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;