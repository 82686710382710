import React, { useEffect, useState } from 'react';
import Banner from '../components/Banner';
import axios from 'axios';
import Loader from '../components/Loader';
import NotFoundComponent from '../components/NotFoundComponent';
import AboutArea from '../components/AboutArea';
import AboutUs from '../components/AboutUs';
import Hotels from '../components/Hotels';
import Interesting from '../components/Interesting';

const Home=()=> {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  
  useEffect(() => {
  
    const getQueryParam = (param) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    };
    const gclid = getQueryParam('gclid');
    if(gclid){
      axios.get('https://api.norgeincasino.com/api/Hotels') 
      .then(response => {
        setData(response.data);
        setLoading(false); 
        setError(response.data.isSuccess==false);
      })
      .catch(error => {
        console.error('API çağrısı sırasında bir hata oluştu:', error);
        setLoading(false); 
      });
    }else{
      setLoading(false)
    }
     
    }, []);
  
  
    if (loading) return <Loader />;
  
    if (error) return <NotFoundComponent />;
  
  return (
    <div>
      

      <Banner/>
      <AboutArea />
      <AboutUs />
      <Hotels />
      <Interesting />
    </div>
  );
}

export default Home;