import React from 'react';

const TermsRegular = () => {


  return (
    <div className="container">
    <div className="wrapper">
        <h1>Generelle avtalevilkår</h1>
        <p>
            Velkommen til siden NorgeInCasino.com!
        </p>
        <p>Disse vilkårene og betingelsene beskriver regler og forskrifter for bruk av nettstedet NorgeInCasino.com, som
            ligger kl NorgeInCasino.com.
        </p>
        <p>Ved å gå inn på denne nettsiden anses du å akseptere disse vilkårene og betingelsene. Ikke fortsett å
            bruke nettstedet NorgeInCasino.com med mindre du godtar alle vilkårene og betingelsene på denne siden.
        </p>
        <p>Følgende terminologi gjelder for disse vilkårene og betingelsene, personvernreglene og den juridiske
            merknaden og alle avtaler: 'Kunde', 'du' og 'du' betyr at du, personen som besøker nettstedet og
            overholder selskapets vilkår og betingelser. 'Selskap', 'vi', 'oss', 'vår', 'vår' og 'US' refererer til
            selskapet vårt. "Fest", "fester" eller "vi" refererer til både kunden og oss. Alle vilkår refererer til
            tilbudet, aksept og vurdering av betaling som er nødvendig for å fullføre prosessen med å bistå Kunden
            på den måten som passer best til Kundens behov i forbindelse med levering av nevnte tjenester av
            Selskapet i samsvar med nederlandsk lov. Bruken av terminologien ovenfor eller andre ord i entall,
            flertall, store bokstaver og/eller han/hun eller de skal anses å være utskiftbare og refererer derfor
            til det samme.
        </p>
        <p><b>Informasjonskapsler</b><br/>
            Vi bruker informasjonskapsler - informasjonskapsel. Ved å gå inn på nettstedet NorgeInCasino.com samtykker du
            til bruk av informasjonskapsler i samsvar med NorgeInCasino.coms personvernregler.
        </p>
        <p>De fleste interaktive nettsteder bruker informasjonskapsler for å få informasjon om brukeren ved hvert
            besøk. Vår nettside bruker informasjonskapsler for å gi funksjonalitet på visse områder og for å lette
            opplevelsen for besøkende på nettstedet vårt. Noen av våre partnere/annonseringspartnere kan også bruke
            informasjonskapsler.
        </p>
        <p><b>Autorisasjon</b><br/>
            Med mindre annet er oppgitt, eier NorgeInCasino.com og/eller dets lisensgivere de immaterielle rettighetene
            til alt materiale på nettstedet NorgeInCasino.com. Alle immaterielle rettigheter forbeholdt. Du kan få tilgang
            til materialet på NorgeInCasino.com-nettstedet for din egen personlige bruk, underlagt begrensningene angitt i
            disse vilkårene og betingelsene.
        </p>
        <p>Du må ikke:

            <br/>
            - Materialet på nettstedet NorgeInCasino.com;
            <br/>
            - Selge, leie eller underlisensiere materiale på nettstedet NorgeInCasino.com;
            <br/>
            - Reprodusere, duplisere eller kopiere materiale fra NorgeInCasino.com-nettstedet;
            <br/>
            - Innholdet på nettstedet NorgeInCasino.com.
        </p>
        <p>Enkelte områder av nettstedet lar brukere legge ut og dele meninger og informasjon i bestemte områder av
            nettstedet. NorgeInCasino.com filtrerer, redigerer, publiserer eller vurderer kommentarer før de legges ut på
            nettstedet. Kommentarer gjenspeiler ikke synspunktene og meningene til NorgeInCasino.com, dets representanter
            og/eller tilknyttede selskaper. Kommentarene gjenspeiler synspunktene og meningene til personen som
            kommenterer. I den grad det er tillatt av gjeldende lov, skal ikke NorgeInCasino.com være ansvarlig for noe
            ansvar, skader eller kostnader forårsaket og/eller pådratt som følge av kommentarer eller bruk og/eller
            publisering og/eller fremkomst av kommentarer på denne nettsiden.
        </p>
        <p>NorgeInCasino.com forbeholder seg retten til å overvåke alle innlegg og fjerne alle innlegg den anser som
            upassende, støtende eller i strid med disse vilkårene og betingelsene.
        </p>
        <p>Du garanterer og representerer at:
            <br/>
            - Du har rett til å legge inn kommentarer på nettstedet vårt, og du har nødvendige tillatelser og
            myndighet til å gjøre det;
            <br/>
            - Kommentarer krenker ikke noen immaterielle rettigheter, inkludert, men ikke begrenset til, tredjeparts
            opphavsrett, patenter eller varemerker;
            <br/>
            - Innlegg vil ikke inneholde ærekrenkende, ærekrenkende, fornærmende, uanstendig eller på annen måte
            ulovlig materiale som kan utgjøre en krenkelse av personvernet.
            <br/>
            - Kommentarer kan ikke brukes til å annonsere eller fremme kommersielle eller personlige aktiviteter
            eller for å fremme kommersielle eller ulovlige aktiviteter.
        </p>
        <p>Du gir herved til NorgeInCasino.com en ikke-eksklusiv lisens til å bruke, reprodusere, modifisere og autorisere
            andre til å bruke, reprodusere og modifisere bidragene dine i alle former, formater eller medier.
        </p>
        <p>Hyperkobling til vårt materiale</p>
        <p>Følgende organisasjoner kan lenke til nettstedet vårt uten skriftlig tillatelse på forhånd:
            <br/>
            - Offentlige organer;
            <br/>
            - Søkemotorer;
            <br/>
            - Nyhetsorganisasjoner;
            <br/>
            - Nettkatalogselskaper kan koble til nettstedet vårt på samme måte som andre børsnoterte selskaper;
            <br/>
            - Akkrediterte selskaper, bortsett fra ideelle organisasjoner, veldedige kjøpesentre og veldedige
            stiftelser, som ikke har lov til å lenke til nettstedet vårt.
        </p>
        <p>Slike enheter kan lenke til nettstedet vårt, våre publikasjoner eller annen informasjon på nettstedet,
            forutsatt at slik lenke (a) ikke er villedende på noen måte; (b) ikke utgjør en falsk sponsing,
            godkjenning eller godkjenning av den tilknyttede parten og dens produkter og/eller tjenester; (c) er
            innenfor konteksten til det koblede nettstedet.
        </p>
        <p>Andre koblingsforespørsler fra følgende typer organisasjoner kan også vurderes og godkjennes:

            <br/>
            - Allment kjente informasjonskilder for forbrukere og/eller bedrifter;
            <br/>
            - dot.com fellesskapsnettsteder;
            <br/>
            - foreninger eller andre grupper som representerer veldedige organisasjoner;
            <br/>
            - distributører av nettkataloger;
            <br/>
            - Internett-portaler;
            <br/>
            - regnskaps-, advokat- og konsulentfirmaer;
            <br/>
            - utdanningsinstitusjoner og fagforeninger.
        </p>
        <p>Vi vil gi koblingsforespørsler fra disse organisasjonene hvis vi fastslår at (a) koblingen ikke er
            skadelig for oss eller våre akkrediterte virksomheter; (b) organisasjonen ikke er negativt tilknyttet
            oss; (c) fordelen for oss fra synligheten til hyperkoblingen oppveier mangelen på reelle kilobyte; og
            (d) lenken er til generell kildeinformasjon.
        </p>
        <p>Disse enhetene kan lenke til nettstedet vårt, forutsatt at lenken (a) ikke på noen måte er villedende;
            (b) ikke falskt antyder sponsing, godkjenning eller godkjenning av den tilknyttede parten og dens
            produkter eller tjenester; og (c) er passende for konteksten til siden som kodebiten er koblet til.
        </p>
        <p>Hvis du er en av organisasjonene som er oppført i punkt 2 og du ønsker å koble til nettstedet vårt, må du
            varsle oss ved å sende oss en <a href="mailto:info@NorgeInCasino.com">info@NorgeInCasino.com</a>. Vennligst oppgi
            navnet ditt, navnet på organisasjonen din, kontaktinformasjon og URL-en til nettstedet ditt, en liste
            over URL-ene du ønsker å koble til nettstedet vårt fra og URL-ene til nettstedene du ønsker å koble til.
            Du bør forvente svar innen 2-3 uker.
        </p>
        <p>Autoriserte organisasjoner kan koble til nettstedet vårt på følgende måter:
            <br/>
            - med vårt firmanavn;
            <br/>
            - eller Uniform Resource Locator;
            <br/>
            - eller enhver annen beskrivelse av vårt koblede nettsted som er passende i kontekst og format for
            innholdet på det koblede nettstedet.
            <br/>
        </p>
        <p>Bruk av NorgeInCasino.com logoen eller annen kunstnerisk design er ikke tillatt for lenker med mindre en
            varemerkeavtale er på plass.
        </p>
        <p><b>IFRAME</b><br/>
            Du kan ikke lage rammer rundt nettstedet vårt som endrer det visuelle utseendet eller utseendet til
            nettstedet vårt på noen måte uten vår skriftlige forhåndstillatelse.
        </p>
        <p><b>Ansvar for innholdet</b><br/>
            Vi er ikke ansvarlige for innholdet som publiseres på nettstedet ditt. Du godtar å forsvare oss mot
            eventuelle krav som oppstår fra din bruk av nettstedet ditt. Ingen nettside kan inneholde lenker som kan
            anses som ærekrenkende, obskøne eller kriminelle eller som krenker, krenker eller på annen måte krenker
            rettighetene til en tredjepart.
        </p>
        <p><b>Din databeskyttelse</b><br/>
            les vår personvernerklæring
        </p>
        <p>Vi forbeholder oss rettighetene</p>

        <p>Vi forbeholder oss retten til å fjerne lenker eller spesifikke referanser til nettstedet vårt. Du
            samtykker i å fjerne lenker til nettstedet vårt umiddelbart på forespørsel. Vi forbeholder oss også
            retten til å endre disse vilkårene og betingelsene og koblingspolicyen når som helst. Ved å lenke til
            nettstedet vårt når som helst, godtar du å følge og overholde disse koblingsvilkårene.
        </p>
        <p>Fjerner lenker fra nettstedet vårt<br/>
            Hvis du finner en kobling på nettstedet vårt støtende av en eller annen grunn, kan du kontakte oss når
            som helst for å gi oss beskjed. Vi vil vurdere forespørsler om å fjerne lenker, men vi er ikke
            forpliktet til å svare direkte til brukeren.
        </p>
        <p>Vi garanterer ikke for nøyaktigheten, fullstendigheten eller riktigheten av informasjonen på nettstedet,
            og vi forplikter oss ikke til å gjøre nettstedet tilgjengelig eller å holde materialet på nettstedet
            oppdatert.
        </p>
        <p><b>Erklæring om ansvar</b><br/>
            I den grad det er tillatt av gjeldende lov, fraskriver vi oss alle representasjoner, garantier og
            betingelser i forhold til nettstedet vårt og bruken av nettstedet. Ingenting i denne
            ansvarsfraskrivelsen er underforstått:

            <br/>
            - begrenser eller utelukker vårt eller ditt ansvar for død eller personskade;
            <br/>
            - begrenser eller utelukker ikke vårt ansvar for svindel eller uredelig uriktig fremstilling eller ditt
            ansvar;
            <br/>
            - begrense vårt eller ditt ansvar på noen måte som ikke er tillatt av gjeldende lov;
            <br/>
            - utelukker vårt eller ditt ansvar, som ikke kan utelukkes under gjeldende lov.
        </p>
        <p> Begrensningene og utelukkelsene av ansvar i denne delen og andre steder i denne ansvarsfraskrivelsen:
            (a) er underlagt det foregående avsnittet; og (b) gjelder for alt ansvar som oppstår under denne
            ansvarsfraskrivelsen, inkludert ansvar for kontraktsbrudd, erstatningsansvar og lovpålagte
            forpliktelser.
        </p>
        <p>Så lenge nettsiden og informasjonen og tjenestene på den er tilgjengelig gratis, er vi ikke ansvarlige
            for tap eller skade.
        </p>
    </div>
</div>
  );
}

export default TermsRegular;
