import React from 'react'
import img7 from "../assets/images/7.webp"
import img8 from "../assets/images/8.webp"
import img9 from "../assets/images/9.webp"
import img14 from "../assets/images/14.webp"
import img15 from "../assets/images/15.webp"
import img16 from "../assets/images/16.webp"
const HotelsList = () => {
  return (
    <section>
		<div class="container p-6">
			<div class="text-center">
				<div class="section-sub-title">
					<h4>Hoteller med kasinoer</h4>
				</div>
				<h3>Velg det perfekte feriemålet</h3>
				<p>NorgeInCasino tilbyr et bredt utvalg av hoteller med kasinoer i Norge, slik at du kan velge den perfekte
					feriedestinasjonen. Vår nettside tilbyr en praktisk og rask hotellvalgprosess og garanterer de beste
					prisene på rom og pakker som inkluderer kasinobesøk.</p>
				<div class="row">
					<div class="col-md-4">
						<img src={img7} alt="" class="img-fluid"/>
						<h4 class="hotel-name"><a href="https://www.booking.com/hotel/no/clarion-hotel-the-hub.ru.html">Clarion Hotel The
								Hub</a></h4>
					</div>
					<div class="col-md-4">
						<img src={img8} alt="" class="img-fluid"/>
						<h4 class="hotel-name"><a href="https://www.booking.com/hotel/no/scandic-vulkan.ru.html">Scandic
								Vulkan</a></h4>
					</div>
					<div class="col-md-4">
						<img src={img9} alt="" class="img-fluid"/>
						<h4 class="hotel-name"><a href="https://www.booking.com/hotel/no/clarion-hotel-the-hub.ru.html">Thon Hotel
								Rosenkrantz </a></h4>
					</div>

					<div class="col-md-4">
						<img src={img14} alt="" class="img-fluid"/>
						<h4 class="hotel-name"><a href="https://www.booking.com/hotel/se/radisson-scandinavia.ru.html">Radisson Blu
								Scandinavia Hotel</a></h4>
					</div>
					<div class="col-md-4">
						<img src={img15} alt="" class="img-fluid"/>
						<h4 class="hotel-name"><a href="https://www.booking.com/hotel/no/clarion-admiral.ru.html">Clarion Hotel Admiral
							</a></h4>
					</div>
					<div class="col-md-4">
						<img src={img16} alt="" class="img-fluid"/>
						<h4 class="hotel-name"><a href="https://www.booking.com/hotel/no/comfort-xpress-central-station.ru.html">Comfort
								Hotel Xpress Central Station</a></h4>
					</div>
				</div>
			</div>
		</div>
	</section>
  )
}

export default HotelsList