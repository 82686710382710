import React from 'react'

const AboutArea = () => {
  return (
    <div className="about-area">
		<div className="container">
			<div className="row p-6">
				<div className="col-lg-4 col-md-12">
					<div className="section-title wow fadeInUp">
						<div className="section-sub-title">
							<h4>Våre fordeler</h4>
						</div>
						<div className="section-main-title about">
							<h3>Bekvemmelighet, pålitelighet og de beste prisene på hoteller med kasinoer i Norge</h3>
						</div>
					</div>
					<div className="about-discription wow fadeInUp">
						<p>NorgeInCasino er det perfekte valget for deg som er ute etter bekvemmelighet og pålitelighet når du
							velger hotell med kasino i Norge. Vår nettside tilbyr en enkel og rask hotellvalgprosess
							over hele landet, slik at du kan velge det perfekte stedet for ferien din. I tillegg
							garanterer vi de beste prisene på rom og pakker som inkluderer kasinobesøk, takket være våre
							direkte forbindelser med hotellene. Vi er også stolte av vårt pålitelige betalingssystem og
							døgnåpne kundestøtte for å sikre at oppholdet ditt går som smurt.</p>
					</div>
				</div>
				<div className="col-lg-8 col-md-12">
					<div className="row">
						<div className="col-md-6">
							<div className="section-main-title text-center">
								<h4>Et bredt utvalg av hoteller</h4>
								<p>Vår side tilbyr et stort utvalg av hoteller med kasinoer i hele Norge, slik at du kan
									velge det perfekte stedet for ferien din.</p>
							</div>
						</div>
						<div className="col-md-6">
							<div className="section-main-title text-center">
								<h4>Garantert de beste prisene</h4>
								<p>Vi samarbeider direkte med hotellene for å kunne tilby deg de beste prisene på rom og
									pakker som inkluderer kasinobesøk.</p>
							</div>
						</div>
						<div className="col-md-6">
							<div className="section-main-title text-center">
								<h4>Enkelt å velge</h4>
								<p>Vår nettside er enkel å bruke og lar deg velge rom på bare noen få minutter. I
									tillegg kan du enkelt endre valget ditt hvis planene dine endres.</p>
							</div>
						</div>
						<div className="col-md-6">
							<div className="section-main-title text-center">
								<h4>Sikker betaling og kundestøtte</h4>
								<p>Vi bruker sikre betalingsmetoder og tilbyr kundestøtte døgnet rundt for å sørge for
									at oppholdet ditt går som smurt.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default AboutArea