import React from 'react'

const CookieInfo = () => {
  return (
    <div className="container">
        <div className="wrapper">
            <h1>Cookie</h1>
            <p>
                Dette nettstedet bruker informasjonskapsler for å forbedre brukeropplevelsen. Ved å bruke dette
                nettstedet godtar du vår bruk av informasjonskapsler.
            </p>
            <p>
                Hva er informasjonskapsler? Informasjonskapsler er små tekstfiler som lagres på datamaskinen din, og som
                gjør det mulig for nettsteder å spore og lagre informasjon om hvordan du bruker nettstedet. Disse filene
                hjelper oss med å analysere hvordan du bruker nettstedet vårt, slik at vi kan gi deg bedre og mer
                relevant innhold.
            </p>
            <p>Hvilke informasjonskapsler bruker vi? Vi bruker følgende typer informasjonskapsler på nettstedet vårt:
                <br/>
                - Nødvendige informasjonskapsler: Disse informasjonskapslene er nødvendige for at nettstedet vårt skal
                fungere, og lar deg bruke grunnleggende funksjoner som innlogging og kjøp.
                <br/>
                - Analytiske informasjonskapsler: Disse informasjonskapslene hjelper oss med å spore hvordan du bruker
                nettstedet vårt, slik at vi kan forbedre ytelsen og innholdet.
                <br/>
                - Informasjonskapsler for annonsering: Disse informasjonskapslene gjør det mulig for oss å gi deg
                relevant annonsering basert på din atferd på nettstedet vårt.
            </p>
            <p>
                Hvordan kan du administrere informasjonskapsler? Du kan aktivere eller deaktivere informasjonskapsler på
                dette nettstedet. Vær imidlertid oppmerksom på at deaktivering av visse typer informasjonskapsler kan
                påvirke nettstedets funksjonalitet.
            </p>
            <p>
                Ved å klikke på "Jeg godtar"-knappen nedenfor, godtar du bruken av alle typer informasjonskapsler på
                dette nettstedet. Hvis du ikke ønsker å godkjenne bruken av informasjonskapsler, kan du velge "Jeg
                godtar ikke" og fortsette å bruke nettstedet, men enkelte funksjoner kan være begrenset.
            </p>
            <p>
                Du finner mer informasjon om vår personvernpolicy og informasjonskapsler på vår informasjonsside om
                personvern.
            </p>
            <p>Takk for tilliten og for at du bruker nettstedet vårt.</p>
        </div>
    </div>
  )
}

export default CookieInfo