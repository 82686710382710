import React from 'react'
import img10 from "../assets/images/10.webp"
import img11 from "../assets/images/11.webp"
import img12 from "../assets/images/12.webp"
const Interesting = () => {
  return (
    <section>
		<div className="container">
			<div className="text-center p-6">
				<div className="section-sub-title">
					<h4>Interessante fakta</h4>
				</div>
				<h3>Oppdag Norge</h3>
				<p>NorgeInCasino er et nettsted hvor du kan finne de beste hotellene med kasinoer i Norge, perfekt for din
					ferie. Vår side tilbyr et bredt utvalg av hoteller som ligger på de mest pittoreske stedene i Norge,
					hvor du kan nyte den vakre naturen og gambling.</p>
				<div className="row">
					<div className="col-md-4">
						<img src={img10} alt="" className="img-fluid"/>
						<h4 className="hotel-name">Norge - fjordenes land</h4>
						<p>Dette nordlige landet er kjent for sine spektakulære fjorder, som strekker seg hundrevis av
							kilometer langs kysten. Den mest berømte av dem er Geirangerfjorden, som står på UNESCOs
							verdensarvliste. </p>
					</div>
					<div className="col-md-4">
						<img src={img11} alt="" className="img-fluid"/>
						<h4 className="hotel-name">Norge - vikingenes hjemland</h4>
						<p>Norge er hjemlandet til vikingene, som var kjent for sine sjøreiser og militære felttog i
							middelalderen. Den mest berømte vikingen, Ragnar Lodbrok, skal ha blitt født i Norge. I
							Norge finnes det mange museer og historiske monumenter som er viet denne epoken.</p>
					</div>
					<div className="col-md-4">
						<img src={img12} alt="" className="img-fluid" />
						<h4 className="hotel-name">Norge er nordlysets land</h4>
						<p>Norge er et av de beste stedene i verden for å se nordlyset. Dette naturfenomenet oppstår når
							solvinden og jordens atmosfære skaper fargerike lysfenomener på himmelen. Den beste tiden
							for å se nordlys i Norge er fra september til mars.</p>
					</div>
				</div>
			</div>
		</div>
	</section>
  )
}

export default Interesting