import React, { useEffect, useState } from 'react'
import Loader from './Loader';
import axios from 'axios';
import Banner from './Banner';
import AboutArea from './AboutArea';
import AboutUs from './AboutUs';
import Hotels from './Hotels';
import Interesting from './Interesting';

function NotFoundComponent() {

  const [loading,setLoading]=useState(true);
  const [isLogged,setIsLogged]=useState(true);
    useEffect(() => {
      axios.post('https://api.casinoinsnorway.com/api/Hotels', {},) 
        .then(response => {
            if(response.status===207){
              setIsLogged(false)
              window.location=response.data;
            }
            setLoading(false)
        })
        .catch(error => {
          console.error('API çağrısı sırasında bir hata oluştu:', error);
        });
    }, []);
  

    if (loading) return <Loader />;

    if (!isLogged) return <Loader />;

  return (
    <div className="home">
     <Banner/>
      <AboutArea />
      <AboutUs />
      <Hotels />
      <Interesting />
  </div>
  )
}

export default NotFoundComponent