import React from 'react';
import Navigation from '../components/Navigation';
import ContactBanner from '../components/ContactBanner';
import ContactForm from '../components/ContactForm';

const Contact=()=> {
  return (
    <div className="about-page">
    
      <ContactBanner/>
      <ContactForm/>
    </div>
  );
}

export default Contact;