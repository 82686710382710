import React from 'react'
import img13 from "../assets/images/13.webp"
const Qusetion = () => {
  return (
    <section>
		<div className="container">
			<div className="row p-6 align-items-center">
				<div className="col-lg-6 col-md-12">
					<div className="section-sub-title">
						<h4>Ofte stilte spørsmål</h4>
					</div>
					<h3>Få mer informasjon om teamet vårt</h3>
					<p><b>01. Hvem står bak opprettelsen og ledelsen av NorgeInCasino?</b><br/>
						Nettstedet vårt ble opprettet og administreres av et team av erfarne reise- og gamblingeksperter
						ledet av Jørgen Hansen, som har over 10 års erfaring innen kasino- og hotellbransjen.</p>
					<p><b>02. Hvordan velger NorgeInCasino ut hoteller?</b><br/>
						NorgeInCasino-teamet velger nøye ut hoteller med kasinoer basert på kriterier som servicekvalitet,
						omdømme, utvalg av kasinospill, tilleggsfasiliteter og beliggenhet. De vurderer også
						gjesteanmeldelser og anmeldelser fra andre for å sikre at kun de beste etablissementene blir
						inkludert.</p>
					<p><b>03. Hvilke andre tjenester tilbyr NorgeInCasino-teamet?</b><br/>
						NorgeInCasino-teamet tilbyr også nyttig informasjon og interessante fakta om Norge, anbefalinger for
						attraksjoner og turistattraksjoner, og tips om hvordan du planlegger den perfekte ferien i
						landet. De tilbyr også spillrådgivning og tips om ansvarlig spilling.</p>
					<p><b>04. Hvordan holder NorgeInCasino-teamet seg oppdatert?</b><br/>
						NorgeInCasino-teamet oppdaterer regelmessig informasjonen på nettstedet for å sikre at den er
						oppdatert og nøyaktig. De lytter også til tilbakemeldinger fra brukere og partnere for å gjøre
						endringer i tide og forbedre kvaliteten på innholdet. I tillegg samarbeider de med lokale
						tjenesteleverandører og eksperter på turisme og gambling for å få pålitelig og oppdatert
						informasjon.</p>
				</div>
				<div className="col-lg-6 col-md-12">
					<img src={img13} alt="" className="img-fluid"/>
				</div>
			</div>
		</div>
	</section>
  )
}

export default Qusetion