import React from 'react';
import Navigation from '../components/Navigation';
import TermsRegular from '../components/TermsRegular';

const Terms=()=> {
  return (
    <div>
     
      <TermsRegular/>
      
    </div>
  );
}

export default Terms;