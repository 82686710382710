import React from 'react'

const ContactInfo = () => {
  return (
    <div className="container">
        <div className="wrapper">
            <h1>Kontakter</h1>
            <p>
                Denne nettsiden eies av NorgeInCasino AS. Vi streber etter å gi relevant og nyttig informasjon til våre
                brukere.
                <br/>
                Selskapsnavn: NorgeInCasino AS
                <br/>
                Offisiell adresse: Gammel-lina 36, 7028 Trondheim, Norge
                <br/>
                E-post: <a href="mailto:info@NorgeInCasino.com">info@NorgeInCasino.com</a>
                <br/>
                Telefon: <a href="tel:+4772353311">+4772353311</a>
                <br/>
                Administrerende direktør: Adelina Andersen
                <br/>
                Registreringsnummer: 901644715
                <br/>
                Navn på registreringsmyndigheten: Justitia Legal Services
                <br/>
                Registreringsmyndighetens adresse: Nordre Hallsetveg 39A, 7023 Trondheim, Norge
            </p>
            <p><b>Innholdsansvar:</b>
                <br/>
                Vi er ikke ansvarlige for innholdet i lenker til tredjeparts nettsteder som kan være tilgjengelige på
                nettstedet vårt. Eierne av slike lenker bærer alt ansvar for innholdet i slike lenker. Vi påtar oss
                intet ansvar for noen direkte eller indirekte skade som oppstår ved bruk av informasjonen på nettstedet
                vårt.
            </p>
            <p><b>Opphavsrett:</b>
                <br/>
                Alt materiale på nettsiden vår, inkludert tekst, bilder, grafikk, design og programvare, er beskyttet av
                opphavsrett. Enhver bruk eller distribusjon uten tillatelse fra opphavsrettseieren er forbudt.
            </p>
            <p><b>Kontaktinformasjon:</b>
                <br/>
                Hvis du har spørsmål eller kommentarer om nettstedet vårt eller selskapet vårt, vennligst kontakt oss
                ved å bruke kontaktdetaljene ovenfor.
            </p>
            <p><b>Personvernerklæring:</b>
                <br/>
                Vi respekterer ditt personvern og beskytter dine personopplysninger. Se vår personvernerklæring for mer
                informasjon.
            </p>
        </div>
    </div>
  )
}

export default ContactInfo