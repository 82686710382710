import React from 'react';
import Navigation from '../components/Navigation';
import Banner from '../components/Banner';
import AboutBanner from '../components/AboutBanner';
import AboutUs from '../components/AboutUs';
import AboutArea from '../components/AboutArea';
import Qusetion from '../components/Qusetion';

const About=()=> {
  return (
    <div className="about-page">
      <AboutBanner/>
      <AboutUs/>
      <Qusetion/>
      <AboutArea/>
    </div>
  );
}

export default About;