import React from 'react';
import {Link} from "react-router-dom"
const Banner=()=> {
  return (
    <section className="banner-section">
		<div className="banner-carousel">
			<div className="slide-item one">
				<div className="container">
					<div className="slider-content">
						<div className="slider-sub-title">
							<h4>Velkommen NorgeInCasino</h4>
						</div>
						<div className="slider-main-title">
							<h1>Beste hotell med kasino i Norge</h1>
						</div>
						<div className="Lifesafe-btn slider1">
							<Link to="/contact">Abonner</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
  );
}

export default Banner;