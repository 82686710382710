import React from 'react'
import { Link } from 'react-router-dom'
import img6 from "../assets/images/6.webp"
const AboutUs = () => {
  return (
    <section>
		<div className="container">
			<div className="row p-6">
				<div className="col-lg-6 col-md-12">
					<img src={img6} alt="" className="img-fluid"/>
				</div>
				<div className="col-lg-6 col-md-12">
					<div className="section-sub-title">
						<h4>Om oss</h4>
					</div>
					<h3>Våre medarbeidere</h3>
					<p>NorgeInCasino-teamet er en gruppe entusiastiske mennesker som elsker å reise og som vet hvor viktig det
						er å velge riktig feriedestinasjon. Vi jobber sammen for å gi våre brukere det beste utvalget av
						hoteller med kasinoer i Norge, samt garantere dem bekvemmelighet og pålitelighet når de
						bestiller. Vårt team består av reiseeksperter som vet alt om de beste hotellene og kasinoene i
						Norge, og som står klare til å hjelpe deg med å velge det perfekte stedet for ferien din.</p>
					<div className="Lifesafe-btn">
						<Link to="/about">Les mer</Link>
					</div>
				</div>
			</div>
		</div>
	</section>
  )
}

export default AboutUs