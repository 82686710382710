import React from 'react'
import HotelBanner from '../components/HotelBanner'
import HotelsList from '../components/HotelsList'

const HotelPage = () => {
  return (
    <div>
        <HotelBanner/>
        <HotelsList/>
    </div>
  )
}

export default HotelPage