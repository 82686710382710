import React from 'react';
import img from "../assets/images/1.webp";

const AboutBanner = () => {
  const bannerStyle = {
    minHeight: '400px',
    background: `url(${img})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  };

  return (
    <section className="banner-section" style={bannerStyle}>
      <div className="container">
        <h1 className="text-white add">Om oss</h1>
      </div>
    </section>
  );
};

export default AboutBanner;