import React from 'react'
import img1 from "../assets/images/1.webp"
const ContactBanner = () => {
    const bannerStyle = {
        minHeight: '400px',
        background: `url(${img1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      };
  return (
    <section className="banner-section" style={bannerStyle}>
		<div className="container">
			<h1 className="text-white add">Kontakt</h1>
		</div>
	</section>
  )
}

export default ContactBanner