import React from 'react'
import PrivacyInfo from '../components/PrivacyInfo'

const Privacy = () => {
  return (
    <div>
        <PrivacyInfo/>
    </div>
  )
}

export default Privacy