import React from 'react';

function Loader() {
  return (
    <div style={loaderStyle}>
      <div style={spinnerStyle}></div>
      <p>Loading...</p>
    </div>
  );
}

// Basit stil tanımlamaları
const loaderStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh'
};

const spinnerStyle = {
  border: '8px solid #f3f3f3', /* Light grey */
  borderTop: '8px solid #3498db', /* Blue */
  borderRadius: '50%',
  width: '60px',
  height: '60px',
  animation: 'spin 1s linear infinite'
};

export default Loader;