import React from 'react';
import { Link } from 'react-router-dom';
import pin from "../assets/images/pin.webp"
import wa from "../assets/images/wa.webp"
import email from "../assets/images/email.webp"
import logo from "../assets/images/Logo.png"
const Footer=()=> {
  return (
    <footer className="footer">
		<div className="container">
			<div className="row">
				<div className="col-md-7">
					<img src={logo} alt="logo" className="logo"/>
				</div>
				<div className="col-md-5">
					<a href="https://no.pinterest.com/pin/3940718411810731/"><img src={pin} alt="logo" className="social"/></a>
					<a href="https://api.whatsapp.com/send/?phone=4772553017&amp;text&amp;type=phone_number&amp;app_absent=0"><img src={wa} alt="logo" className="social"/></a>
					<a href="mailto:info@NorgeInCasino.com"><img src={email} alt="logo" className="social"/></a>
				</div>
				<div className="col-md-6">
					<b>Kontaktinformasjon:</b>
					<br/>
					<p>
						NorgeInCasino AS
						<br/>
						Gammel-lina 36, 7028 Trondheim, Norge
						<br/>
						<a href="tel:+4772353311">+4772353311</a>
						<br/>
						<a href="mailto:info@NorgeInCasino.com">info@NorgeInCasino.com</a>
					</p>
				</div>
				<div className="col-md-3">
					<ul>
						<li>
							<Link to="/privacy">Privacybeleid</Link>
						</li>
						<li>
							<Link to="/terms">Algemene voorwaarden</Link>
						</li>
						<li>
							<Link to="/contactinfo">Contact</Link>
						</li>
						<li>
							<Link to="/cookie">Cookie</Link>
						</li>
					</ul>
				</div>
				<div className="col-md-3">
					<ul>
						<li><Link to="/">Hjem</Link></li>
						<li><Link to="/about">Om oss</Link></li>
						<li><Link to="/contact">Kontakt</Link></li>
					</ul>
				</div>
			</div>
			<div className="copyright">Alle rettigheter forbeholdes.
				2024
			</div>
		</div>
	</footer>
  );
}

export default Footer;