import React from 'react'
import img7 from "../assets/images/7.webp"
import img8 from "../assets/images/8.webp"
import img9 from "../assets/images/9.webp"
import { Link } from 'react-router-dom'
const Hotels = () => {
  return (
    <section>
		<div className="container">
			<div className="text-center">
				<div className="section-sub-title">
					<h4>Hoteller med kasinoer</h4>
				</div>
				<h3>Velg det perfekte feriemålet</h3>
				<p>NorgeInCasino tilbyr et bredt utvalg av hoteller med kasinoer i Norge, slik at du kan velge den perfekte
					feriedestinasjonen. Vår nettside tilbyr en praktisk og rask hotellvalgprosess og garanterer de beste
					prisene på rom og pakker som inkluderer kasinobesøk.</p>
				<div className="row">
					<div className="col-md-4">
						<img src={img7} alt="" className="img-fluid"/>
						<h4 className="hotel-name"><a href="https://www.booking.com/hotel/no/clarion-hotel-the-hub.ru.html">Clarion Hotel The
								Hub</a></h4>
					</div>
					<div className="col-md-4">
						<img src={img8} alt="" className="img-fluid"/>
						<h4 className="hotel-name"><a href="https://www.booking.com/hotel/no/scandic-vulkan.ru.html">Scandic
								Vulkan</a></h4>
					</div>
					<div className="col-md-4">
						<img src={img9} alt="" className="img-fluid"/>
						<h4 className="hotel-name"><a href="https://www.booking.com/hotel/no/clarion-hotel-the-hub.ru.html">Thon Hotel
								Rosenkrantz </a></h4>
					</div>
				</div>
				<div className="Lifesafe-btn m-4">
					<Link to="/hotels">Se mer</Link>
				</div>
			</div>
		</div>
	</section>
  )
}

export default Hotels