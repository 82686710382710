import React from 'react'
import { Link } from 'react-router-dom'

const ContactForm = () => {
  return (
    <div className="row contact">
		<div className="col-lg-4 col-md-12">
			<div className="contact-single-box">
				<div className="contact-items">
					<div className="contact-content">
						<h4>Kontoradresse</h4>
						<h6>Gammel-lina 36, 7028 Trondheim, Norge</h6>
					</div>
				</div>
				<div className="contact-items">
					<div className="contact-content">
						<h4>Telefonnummer</h4>
						<h6><a href="tel:+4772353311">+4772353311</a></h6>
					</div>
				</div>
				<div className="contact-items">
					<div className="contact-content">
						<h4>E-postadresse</h4>
						<h6><a href="mailto:info@NorgeInCasino.com">info@NorgeInCasino.com</a></h6>
					</div>
				</div>
			</div>
		</div>
		<div className="col-lg-8 col-md-12">
			<div className="contact-box">
				<div className="contact-box-title text-center">
					<h4>Bli en del av oss!</h4>
					<p>Ikke gå glipp av sjansen til å bli en del av vårt fellesskap og oppdage det virkelige Norge.</p>
				</div>
				<form action="thanks.php" method="get">
					<div className="row">
						<div className="col-lg-6 col-md-6">
							<div className="form-box">
								<input type="text" name="name" placeholder="Ditt navn"/>
							</div>
						</div>
						<div className="col-lg-6 col-md-6">
							<div className="form-box">
								<input type="text" name="email" placeholder="E-postadresse"/>
							</div>
						</div>
						<div className="custom-form-group">
							<input type="checkbox" name="terms" id="terms" required=""/>
							<span className="mandatory">*</span>
							<label className="confirm-small" for="terms">Jeg har lest og godtar de <Link to="/terms">generelle vilkårene og betingelsene</Link>.</label>
						</div>
						<div className="custom-form-group">
							<input type="checkbox" name="send_newsletter" id="send_newsletter"/>
							<label className="confirm-small" for="send_newsletter">Jeg ønsker å fortsette å motta nyttige
								nyheter og verdifulle tips, og jeg samtykker til behandling av personopplysningene mine
								i e-postadressen min.</label>
						</div>
						<div className="form-box-button">
							<input type="submit" id="submit" name="send" className="submitBnt" value="Abonner"/>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
  )
}

export default ContactForm