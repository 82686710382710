import React from 'react';
import Navigation from '../components/Navigation';
import CookieInfo from '../components/CookieInfo';

const Cookie=()=> {
  return (
    <div>
      
     <CookieInfo/>
      
      
    </div>
  );
}

export default Cookie;